<template>
  <div class="container mt-5">
    <h3 class="text-center mb-4">Gestión de Préstamos de Equipos</h3>

    <!-- Formulario para agregar un nuevo préstamo -->
    <form @submit.prevent="agregarPrestamo" class="card p-4 shadow-sm mb-5">
      <h4 class="card-title text-primary mb-3">Agregar nuevo préstamo</h4>

      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="docente" class="form-label">Docente</label>
            <select class="form-control" id="docente" v-model="nuevoPrestamo.usuario_id" required>
              <option v-for="docente in docentes" :key="docente.id" :value="docente.id">
                {{ docente.nombre }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label for="equipo" class="form-label">Equipo</label>
            <select class="form-control" id="equipo" v-model="nuevoPrestamo.activo_id" required>
              <option v-for="equipo in equiposFiltrados" :key="equipo.id" :value="equipo.id">
                {{ equipo.nombre }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label for="utilidad" class="form-label">Utilidad del equipo</label>
            <select class="form-control" id="utilidad" v-model="nuevoPrestamo.utilidad" required>
              <option value="Presentación">Presentación</option>
              <option value="Clase">Clase</option>
              <option value="Reunión">Reunión</option>
              <option value="Otro">Otro</option>
            </select>
          </div>

          <div class="mb-3">
            <label for="fechaEntrega" class="form-label">Fecha de entrega</label>
            <input
              type="date"
              class="form-control"
              id="fechaEntrega"
              v-model="nuevoPrestamo.fecha_entrega"
              min="2024-01-01"
              max="2024-12-31"
              required
            />
          </div>

          <button type="submit" class="btn btn-primary w-100 mt-3">
            <i class="bi bi-plus-circle"></i> Agregar préstamo
          </button>
        </div>

        <div class="col-6">
          <div class="card p-4 shadow-sm">
            <h4 class="card-title text-primary mb-3">Estadísticas de Equipos</h4>
            <p><strong>Total de equipos registrados:</strong> {{ equipos.length }}</p>
            <p>
              <strong>Equipos en uso:</strong>
              {{ prestamos.filter((p) => p.estado === "prestado").length }}
            </p>
            <p>
              <strong>Equipos dañados:</strong>
              {{ equipos.filter((e) => e.estado === "Dañado").length }}
            </p>
          </div>
        </div>
      </div>
    </form>

    <!-- Tabla de equipos en préstamo -->
    <div class="table-responsive">
      <table class="table table-striped table-hover mt-4 shadow-lg">
        <thead class="table-dark">
          <tr>
            <th>Docente</th>
            <th>Equipo</th>
            <th>Utilidad</th>
            <th>Fecha (Préstamo / Entrega)</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(prestamo, index) in prestamos" :key="index">
            <td>{{ obtenerNombreDocente(prestamo.usuario_id) }}</td>
            <td>{{ obtenerNombreEquipo(prestamo.activo_id) }}</td>
            <td>{{ prestamo.utilidad }}</td>
            <td>{{ prestamo.fecha_prestamo }} / {{ prestamo.fecha_devolucion}}</td>
            <td>
              <span :class="obtenerClaseEstado(prestamo)" class="badge rounded-pill text-white">
                {{ prestamo.estado }}
              </span>
            </td>
            <td>
              <button class="btn btn-success btn-sm me-2" @click="confirmarEntrega(prestamo.id)">
                <i class="bi bi-check-circle"></i>
              </button>
              <button class="btn btn-danger btn-sm" @click="eliminarPrestamo(prestamo.id)">
                <i class="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  fetchActivos,
  fetchPrestamos,
  addPrestamo,
  devolverPrestamo,
  eliminarPrestamoAPI,
  fetchUsuarios,
} from "../services/api";

export default {
  data() {
    return {
      docentes: [],
      equipos: [],
      prestamos: [],
      nuevoPrestamo: {
        usuario_id: "",
        activo_id: "",
        utilidad: "",
        fecha_devolucion: "",
      },
    };
  },
  computed: {
    equiposFiltrados() {
      return this.equipos.filter(
        (equipo) =>
          !this.prestamos.some(
            (prestamo) => prestamo.activo_id === equipo.id && prestamo.estado === "prestado"
          )
      );
    },
  },
  methods: {
    async cargarDatos() {
      this.equipos = await fetchActivos();
      this.prestamos = await fetchPrestamos();
      this.docentes = await fetchUsuarios();
      console.log("Préstamos cargados:", this.prestamos); // 🛠 Verifica si hay fecha_devolucion

    },
    async agregarPrestamo() {
      try {
        await addPrestamo(
          this.nuevoPrestamo.activo_id,
          this.nuevoPrestamo.usuario_id,
          this.nuevoPrestamo.utilidad,
          this.nuevoPrestamo.fecha_entrega
        );

        alert("Préstamo agregado correctamente");
        this.cargarDatos();

        // Limpiar formulario
        this.nuevoPrestamo = { usuario_id: "", activo_id: "", utilidad: "", fecha_devolucion: "" };
      } catch (error) {
        console.error("Error al agregar préstamo:", error);
        alert("Error al agregar préstamo: " + error.message);
      }
    },
    async confirmarEntrega(id) {
      await devolverPrestamo(id);
      this.cargarDatos();
    },
    async eliminarPrestamo(id) {
      if (!confirm("¿Seguro que quieres eliminar este préstamo?")) return;

      try {
        const respuesta = await eliminarPrestamoAPI(id);
        alert(respuesta.mensaje || "Préstamo eliminado correctamente"); // 🔥 Ahora usamos la variable
        this.cargarDatos();
      } catch (error) {
        console.error("Error al eliminar préstamo:", error);
        alert("Error al eliminar préstamo: " + error.message);
      }
    },
    obtenerNombreDocente(id) {
      const docente = this.docentes.find((d) => d.id === id);
      return docente ? docente.nombre : "Desconocido";
    },
    obtenerNombreEquipo(id) {
      const equipo = this.equipos.find((e) => e.id === id);
      return equipo ? equipo.nombre : "Desconocido";
    },
    obtenerClaseEstado(prestamo) {
      return prestamo.estado === "prestado" ? "bg-warning" : "bg-success";
    },
  },
  mounted() {
    this.cargarDatos();
  },
};
</script>

<style scoped>
.table th,
.table td {
  text-align: center;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.badge {
  font-size: 14px;
}

.bi {
  font-size: 20px;
}
</style>
