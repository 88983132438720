const API_URL = "https://www.colegiovisionmundial.edu.co/api/";

export async function fetchActivos() {
    const response = await fetch(`${API_URL}activos.php`);
    return response.json();
}

export async function fetchPrestamos() {
    const response = await fetch(`${API_URL}prestamos.php`);
    return response.json();
}

export async function fetchUsuarios() {
    const response = await fetch(`${API_URL}usuarios.php`);
    return response.json();
}

export async function addPrestamo(activo_id, usuario_id) {
    const response = await fetch(`${API_URL}prestamos.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ activo_id, usuario_id }),
    });
    return response.json();
}

export async function devolverPrestamo(id_prestamo) {
    const response = await fetch(`${API_URL}prestamos.php`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id_prestamo }),
    });
    return response.json();
}

export async function eliminarPrestamoAPI(id_prestamo) {
    const response = await fetch(`${API_URL}prestamos.php`, { 
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id_prestamo })
    });

    if (!response.ok) {
        throw new Error(`Error al eliminar: ${response.statusText}`);
    }

    return response.json();
}

export async function addEquipo(nombre, estado) {
    const response = await fetch(`${API_URL}activos.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ nombre, estado }),
    });
    return response.json();
}

export async function deleteEquipo(id) {
    const response = await fetch(`${API_URL}activos.php`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id }),
    });
    return response.json();
}

