<template>
    <div class="container my-3">
        <div class="container p-1 bg-co rounded">
            <div class="text-center mb-4">
                <h2 class="text-pri mt-2 text-uppercase fw-bold">Evaluación Docente I Período</h2>
                <hr class="divider">
            </div>
            <img src="@/assets/PerfilDocente/Docentesimg3.png" alt="Evaluación Docente" class="img-fluid rounded" />

            <p class="lead p-2 text-justify ">
                Esta evaluación permite a los
                estudiantes ofrecer retroalimentación valiosa sobre el desempeño de sus maestros, contribuyendo así a un
                ambiente educativo más enriquecedor y eficaz. Su participación en esta evaluación es crucial para
                identificar áreas de fortaleza y oportunidades de mejora en la práctica docente, ayudando a garantizar
                que todos los estudiantes reciban una educación de alta calidad.
            </p>
            <h4>Instrucciones</h4>
            <ol class="list-decimal ms-2 text-justify">
                <li><strong>Elija al Docente:</strong> Seleccione el docente que desea evaluar. Asegúrese de tener en
                    cuenta a todos los maestros con los que ha interactuado durante el período.</li>
                <li><strong>Acceso al Formulario:</strong> Una vez que haya elegido al docente, será redirigido a un
                    formulario para completar la evaluación.</li>
                <li><strong>Inicio de Sesión:</strong> Para acceder al formulario, debe ingresar con su correo
                    institucional. Si no utiliza su <strong>Correo Institucional</strong>, no podrá completar la evaluación. Asegúrese de
                    estar conectado con su cuenta institucional antes de intentar acceder.</li>
                <li><strong>Complete la Evaluación:</strong> Llene el formulario proporcionando comentarios honestos y
                    constructivos sobre el docente seleccionado. Su feedback es esencial para mejorar la calidad
                    educativa.</li>
            </ol>
            <p class="text-muted mt-4">
                Recuerde que sus respuestas son confidenciales y serán utilizadas únicamente para fines de evaluación y
                mejora. ¡Gracias por su participación!
            </p>
        </div>

        <!-- Acordeón de categorías -->
        <div class="accordion" id="docenteAccordion">
            <!-- Preescolar -->
            <div class="accordion-item my-2">
               <h2 class="accordion-header" id="headingPreescolar">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsePreescolar" aria-expanded="true" aria-controls="collapsePreescolar">
                        ➡️ Docentes Visionario - <strong> Preescolar</strong>
                    </button>

                    
                </h2>
                <div id="collapsePreescolar" class="accordion-collapse collapse show"
                    aria-labelledby="headingPreescolar" data-bs-parent="#docenteAccordion">
                    <div class="accordion-body">
                        <div class="grid-card">
                            <div class="card" v-for="docente in docentesPreescolar" :key="docente.id"
                                @click="redirectToForm(docente.formLink)">
                                <!-- Usar require para imágenes -->
                                <img :src="require(`@/assets/PerfilDocente/${docente.foto}`)" class="card-img-top"
                                    alt="Foto del docente">
                                <div class="card-body">
                                    <h6 class="card-title">{{ docente.nombre }}</h6>

                                    <p class="card-text">Docente: {{ docente.grado }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Primaria -->
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingPrimaria"> 
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsePrimaria" aria-expanded="false" aria-controls="collapsePrimaria">
                        ➡️ Docentes Visionario - <strong> Primaria</strong>
                    </button>
                </h2>
                <div id="collapsePrimaria" class="accordion-collapse collapse" aria-labelledby="headingPrimaria"
                    data-bs-parent="#docenteAccordion">
                    <div class="accordion-body">
                        <div class="grid-card">
                            <div class="card" v-for="docente in docentesPrimaria" :key="docente.id"
                                @click="redirectToForm(docente.formLink)">
                                <!-- Usar require para imágenes -->
                                <img :src="require(`@/assets/PerfilDocente/${docente.foto}`)" class="card-img-top"
                                    alt="Foto del docente">
                                <div class="card-body">
                                    <h6 class="card-title">{{ docente.nombre }}</h6>
                                    <p class="card-text">Docente: {{ docente.grado }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Media y Secundaria -->
            <div class="accordion-item my-2">
                <h2 class="accordion-header" id="headingMediaSecundaria">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseMediaSecundaria" aria-expanded="false"
                        aria-controls="collapseMediaSecundaria">
                        ➡️ Docentes Visionario - <strong> Media y Secundaria</strong>
                    </button>
                </h2>
                <div id="collapseMediaSecundaria" class="accordion-collapse collapse"
                    aria-labelledby="headingMediaSecundaria" data-bs-parent="#docenteAccordion">
                    <div class="accordion-body">
                        <div class="grid-card">
                            <div class="card" v-for="docente in docentesMediaSecundaria" :key="docente.id"
                                @click="redirectToForm(docente.formLink)">
                                <!-- Usar require para imágenes -->
                                <img :src="require(`@/assets/PerfilDocente/${docente.foto}`)" class="card-img-top"
                                    alt="Foto del docente">
                                <div class="card-body">
                                    <h6 class="card-title">{{ docente.nombre }}</h6>
                                    <hr class="divider">

                                    <p class="card-text">Docente: {{ docente.grado }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        
            isAccordionEnabled: false, // Esto controla si el acordeón está habilitado
            docentesPreescolar: [
                { id: 1, nombre: 'Aura Caraballo Argel', grado: 'Pre-Jardín', foto: 'AURA.png', formLink: 'https://forms.gle/btLAVgzjZTGsPuFX7' },
                { id: 2, nombre: 'Kenia Castillo Bonilla', grado: 'Jardín', foto: 'KENIA.png', formLink: 'https://forms.gle/5Mn4ENaCT8VPWcYx8' },
                { id: 3, nombre: 'Silvia Durango Creus', grado: 'Transición', foto: 'SILVIA.png', formLink: 'https://forms.gle/4NsUSX8fNqDBDJBG6' },
                { id: 4, nombre: 'Karina Montero Bedoya', grado: 'Inglés', foto: 'KARINA.png', formLink: 'https://forms.gle/NZsrQUZ6qU1pp37P8' },
                { id: 5, nombre: 'Dilson Maza Pernett', grado: 'Educación Física', foto: 'DILSON.png', formLink: 'https://forms.gle/yvaS1Vwat9ZrgkPe6' },
                { id: 6, nombre: 'Ana Gómez Buelvas', grado: 'Inglés', foto: 'ANA.png', formLink: 'https://forms.gle/YjafG6TqZnVUNA888' },
            ],

            docentesPrimaria: [
                { id: 7, nombre: 'María Vertel González', grado: '1° - Primero', foto: 'MARIAV.png', formLink: 'https://forms.gle/L1Boh8K9YFjntbrE8' },
                { id: 8, nombre: 'Libia Pernett Galarcio', grado: '2° - Segundo', foto: 'LIBIATT.png', formLink: 'https://forms.gle/GE3sHuVimWhcVNKE6' },
                { id: 9, nombre: 'María Solano Sánchez', grado: '3° - Tercero', foto: 'MARIAS.png', formLink: 'https://forms.gle/gGqVPL8FnEMb5vEY7' },
                { id: 10, nombre: 'Karina Montero Bedoya', grado: '4° - Cuarto', foto: 'KARINA.png', formLink: 'https://forms.gle/NZsrQUZ6qU1pp37P8' },
                { id: 11, nombre: 'José Hoyos Padilla', grado: '5° - Quinto', foto: 'JOSE.png', formLink: 'https://forms.gle/ooNDmQmZrQXL4pKz5' },
                { id: 12, nombre: 'Wendy Pertuz Jiménez', grado: 'Lengua Castellana', foto: 'WENDY.png', formLink: 'https://forms.gle/hJTrV2gamKAe83EA9' },
                { id: 13, nombre: 'Dilson Maza Pernett', grado: 'Educación Física', foto: 'DILSON.png', formLink: 'https://forms.gle/yvaS1Vwat9ZrgkPe6' },
                { id: 14, nombre: 'Ana Gómez Buelvas', grado: 'Inglés', foto: 'ANA.png', formLink: 'https://forms.gle/YjafG6TqZnVUNA888' },

            ],
            docentesMediaSecundaria: [
                { id: 13, nombre: 'Wendy Pertuz Jiménez', grado: 'Lengua Castellana', foto: 'WENDY.png', formLink: 'https://forms.gle/hJTrV2gamKAe83EA9' },
                { id: 14, nombre: 'Javier Daza Barreto', grado: 'Matemáticas - Estadística - Geometría', foto: 'JAVIER.png', formLink: 'https://forms.gle/no1yZsanE8TQqvW69' },
                { id: 15, nombre: 'Shirley Pedroza Banda', grado: 'Ciencias Naturales', foto: 'SHIRLEY.png', formLink: 'https://forms.gle/WW3fpktZiNH6wjM48' },
                { id: 16, nombre: 'Jesus Rodríguez Prasca', grado: 'Ciencias Sociales - Emprendimiento', foto: 'JESUS.png', formLink: 'https://forms.gle/TzznVnN9Te9HUD2V9' },
                { id: 17, nombre: 'Jean Otero Pantoja', grado: 'Matemáticas - Estadística - Geometría', foto: 'JEAN.png', formLink: 'https://forms.gle/PcEQtc8XXE6EgBvh7' },
                { id: 18, nombre: 'Kelly González Ricardo', grado: 'Artística - Ética', foto: 'KELLY.png', formLink: 'https://forms.gle/5tjAdrnBwvfiWidg7' },
                { id: 19, nombre: 'Jesus Esquivel Grau', grado: 'Ciencias Naturales - Química', foto: 'JESUSE.png', formLink: 'https://forms.gle/5ogJ6MZCB3PjjDor8' },
                { id: 20, nombre: 'Petrona Altamiranda Sierra', grado: 'Lengua Castellana - Religión', foto: 'PETRONA.png', formLink: 'https://forms.gle/HcnKs1xkCMyfFCYf7' },
                { id: 21, nombre: 'Arnold Pérez Ayala', grado: ' Ciencias Sociales - Ciencias Política - Filosofía', foto: 'ARNOLD.png', formLink: 'https://forms.gle/68zUSbX2KBxuDnYc6' },
                { id: 22, nombre: 'Mauricio Cuadrado Martínez', grado: 'Geometría - Estadística - Física', foto: 'MAURICIO.png', formLink: 'https://forms.gle/Uc28PM6QATxM8QJN7' },
                { id: 23, nombre: 'Ana Gómez Buelvas', grado: 'Inglés', foto: 'ANA.png', formLink: 'https://forms.gle/YjafG6TqZnVUNA888' },
                { id: 24, nombre: 'Alejandro Babilonia Doria', grado: 'Inglés', foto: 'ALEJANDRO.png', formLink: 'https://forms.gle/awLuNntwuWchUpbB9' },
                { id: 25, nombre: 'Lina Atencia García', grado: 'Lengua Castellana', foto: 'LINA.png', formLink: 'https://forms.gle/Xe72oErkBcsvSupr7' },
                { id: 26, nombre: 'Laura González Padilla', grado: 'Tec. Informática', foto: 'LAURA.png', formLink: 'https://forms.gle/3ErZKVL77TPntMQJ8' },
                { id: 27, nombre: 'Dilson Maza Pernett', grado: 'Educación Física', foto: 'DILSON.png', formLink: 'https://forms.gle/yvaS1Vwat9ZrgkPe6' },
            ],
        };
    },
    methods: {
        redirectToForm(link) {
            window.open(link, '_blank');
        },
        toggleAccordion() {
            this.isAccordionEnabled = !this.isAccordionEnabled;
        },
    },
}
</script>

<style scoped>
.text-pri {
    color: var(--color-se);
    /* Usar color secundario para el título */
}

.text-justify {
    text-align: justify;
}

.lead {
    color: var(--color-se);
    font-size: medium;
    /* Color secundario para el texto destacado */
}

.bg-co {
    background-color: var(--color-co);
    /* Fondo blanco */
}

.p-3 {
    padding: 1rem;
    /* Espaciado interno */
}

.rounded {
    border-radius: .25rem;
    /* Bordes redondeados */
}

.grid-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    justify-items: center;
    /* Centra las tarjetas horizontalmente */
}

.card {
    border-width: 2px;
    border-color: var(--color-se);
    /* Colores de borde */

    cursor: pointer;
    max-width: 300px;
    /* Limita el ancho máximo de las tarjetas */
    background-color: var(--color-co);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    /* Suavizar la transformación */

}



.card-img-top {
    height: 280px;
    object-fit: contain;
}


.card-title {
    color: var(--color-se);
    text-transform: uppercase;
    background-color: var;
    font-weight: bold;
}

.card-text {
    color: var(--color-pr);
    font-weight: bold;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-color: var(--color-pr);
    /* Colores de borde */
    background-color: var(--color-co);
    transform: scale(1.03) rotate(1deg);
    /* Agrandar y rotar ligeramente */

}

@media (max-width: 576px) {
  .accordion-button {
    font-size: 0.9rem; /* Tamaño de fuente más pequeño en dispositivos móviles */
    padding: 0.5rem; /* Reduce el padding para mejorar el ajuste en pantallas pequeñas */
    text-align: left; /* Alineación a la izquierda para el contenido */
  }

  .accordion-header {
    padding: 0; /* Elimina padding extra en el header */
  }
}

.accordion-button {
    background-color: var(--color-co);
    color: var(--color-pr);
    border: 1px solid var(--color-pr);
    text-transform: uppercase;
    transition: background-color 0.2s, box-shadow 0.2s;
    /* Transición para suavizar el cambio de color y sombra */
}

.accordion-button:not(.collapsed) {
    background-color: var(--color-pr);
    color: var(--color-co);
    font-weight: bold;
    text-transform: uppercase;
}

.accordion-button:focus,
/* Para el estado de enfoque (cuando se hace clic) */
.accordion-button:active {
    /* Para el estado activo (cuando se mantiene presionado) */
    box-shadow: 0 0 0px 0px rgba(0, 0, 255, 0.5);
    /* Resplandor azul */
}

.accordion-button::after {
    filter: invert(1);
}
</style>